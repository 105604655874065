<template>
  <div class="member-shop-point-exchange-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="資訊頁標題" prop="title" align="center" />
      <BaseElTableColumn label="公開狀態" align="center" width="120px">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'published') ? 'action' : 'info'">{{ get(scope.row, 'published') ? '公開' : '不公開' }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="公開起始時間" prop="publishedStartAt" align="center" width="128px" />
      <BaseElTableColumn label="結束時間" prop="publishedEndAt" align="center" width="128px" />
      <BaseElTableColumn label="是否為會員中心佈告欄的資訊頁" align="center" width="148px">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'bindMemberCenterAd') ? 'action' : 'info'">{{ get(scope.row, 'bindMemberCenterAd') ? '是' : '否' }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="資訊頁網址" align="center">
        <template slot-scope="scope">
          <BaseElButton type="text" class="btn edit" @click="copyLink(scope.row.clientLink)">
            <SVGIcon icon="bulletin-stroke_copy" width="24" height="24" fill="var(--primary-100)" />
            複製網址
          </BaseElButton>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" align="center" fixed="right" width="100px">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @delete="onRowDelete(scope.row)"
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="modal.delete"
      :title="modal.deleteTitle"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      :loading="loading.delete"
      @close="modal.delete = false"
      @delete="deleteData"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref, watchEffect } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { CheckBindMemberCenterAd, DeleteClientAnnouncement, GetClientLink } from '@/api/bulletin'
import Tag from '@/components/Tag/Tag.vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { get } from 'lodash'
import { useRouter } from 'vue-router/composables'
import store from '@/store'
import { formatDate } from '@/utils/date'
import dayjs from '@/lib/dayjs'
import copy from 'clipboard-copy'

export default defineComponent({
  name: 'MemberCenterBulletinDetailTable',
  components: { EmptyBlock, Tag, TableEditBtnGroup, DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const loading = reactive({
      delete: false,
    })
    const displayData = ref([])
    watchEffect(async () => {
      const data = props.tableData
      const results = await Promise.all(data.map(async i => {
        return {
          title: i.title,
          published: await checkPublished(i),
          publishedStartAt: i.publishedStartAt ? formatDate(i.publishedStartAt) : '-',
          publishedEndAt: i.publishedEndAt ? formatDate(i.publishedEndAt) : '-',
          bindMemberCenterAd: await checkBindMemberCenterAd(i.id),
          clientLink: await getClientLink(i.id),
          id: i.id,
        }
      }))
      displayData.value = results
    })
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
      deleteTitle: '提醒',
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberCenterBulletinDetailEdit',
        params: { id: row.id },
      })
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      if (row.bindMemberCenterAd) {
        modal.deleteTitle = '此資訊頁為會員中心佈告欄的連結'
      } else {
        modal.deleteTitle = '提醒'
      }
      modal.delete = true
    }

    const deleteData = async () => {
      if (loading.delete) return
      loading.delete = true
      const [, err] = await DeleteClientAnnouncement({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      loading.delete = false
      if (err) return window.$message.error(err)
      modal.delete = false
      window.$message.success('刪除成功！')
      emit('refresh')
    }
    const checkPublished = async (item) => {
      const [publishedStartAt, publishedEndAt] = [get(item, 'publishedStartAt'), get(item, 'publishedEndAt')]
      if (publishedEndAt) return dayjs().isAfter(publishedStartAt) && dayjs().isBefore(publishedEndAt)
      else return dayjs().isAfter(publishedStartAt)
    }

    const checkBindMemberCenterAd = async (id) => {
      const [res, err] = await CheckBindMemberCenterAd({
        shopId: shopId.value,
        id: id,
      })
      if (err) return window.$message.error(err)
      return get(res, 'exist')
    }

    const getClientLink = async (id) => {
      const [res, err] = await GetClientLink({
        shopId: shopId.value,
        id: id,
      })
      if (err) return window.$message.error(err)
      return get(res, 'link')
    }
    const copyLink = (link) => {
      copy(link)
      window.$message.success(`已複製佈告資訊頁網址 ${link}`)
    }

    return { get, loading, displayData, selectRow, modal, deleteData, onRowEdit, onRowDelete, formatDate, copyLink }
  },
})
</script>

<style lang="postcss" scoped>

.btn {
  @apply underline font-normal
}
::v-deep .btn span {
  @apply flex items-center
}
</style>
